import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Call from "../components/Call";

import "../scss/ytcard.scss";
import "../scss/socialicons.scss";
import axios from "axios";

const Home = (props) => {
  const [data, setData] = useState({ items: [] });
  useEffect(() => {
    axios
      .get(
        "https://www.googleapis.com/youtube/v3/search?part=snippet&order=date&channelId=UCHbzxPstXVVGe3KMj0-xZ7A&key=AIzaSyBnPT0ZIkEcJIUQtP1LA2e1DJ-1EtuxLkE&maxResults=6"
      )
      .then((x) => {
        setData(x.data);
      });
  }, []);

  const intro = props.data.intro;
  const site = props.data.site.siteMetadata;
  const services = props.data.services.edges;
  const features = props.data.features.edges;
  const introImageClasses = `intro-image ${
    intro.frontmatter.intro_image_absolute && "intro-image-absolute"
  } ${
    intro.frontmatter.intro_image_hide_on_mobile && "intro-image-hide-mobile"
  }`;

  return (
    <Layout bodyClass="page-home">
      <SEO title={site.title} />
      <Helmet>
        <meta name="description" content="Official EarnWithRk Youtuber" />
      </Helmet>
      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
              <Call showButton />
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img
                  alt={intro.frontmatter.title}
                  className={introImageClasses}
                  src={intro.frontmatter.intro_image}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="strip">
      <div className="container pt-5 pb-5 pb-md-5" style={{background:'#E8F8F5',borderRadius:10}}>
          <div className="row justify-content-center">
            <h2 className="feature-title">Follow us on Social</h2>
          </div>
          <div className="row" style={{justifyContent:'center'}}>
            <div style={{marginTop: 20 }}>
              <a href="https://www.facebook.com/EarnWithRK" class="fa fa-facebook"></a>
              <a href="https://t.me/earnwithrk" class="fa fa-telegram"></a>
              <a href="https://twitter.com/earnwithrk" class="fa fa-twitter"></a>
              <a href="http://youtube.com/c/EarnWithRK/" class="fa fa-youtube"></a>
              <a href="https://www.instagram.com/elitecryptotraders/?hl=en" class="fa fa-instagram"></a>
            </div>
          </div>
        </div>
      </div>

      <div className="strip">
        <div className="container pt-6 pb-6 pb-md-10">
          <div className="row justify-content-center">
            <h2 className="feature-title">Watch Our Latest Video</h2>

            <div
              className="row justify-content-start"
              style={{ marginTop: 20 }}
            >
              {data.items.slice(0, 6).map((v, k) => (
                <div className="col-12 col-md-4 mb-3">
                  <a
                    href={"https://www.youtube.com/watch?v=" + v.id.videoId}
                    target="_blank"
                    className="ytlink"
                  >
                    <div className="service service-summary">
                      <div
                        className="service-content ytcard"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img src={v.snippet.thumbnails.medium.url} alt="" />
                        <h5
                          className="service-title"
                          style={{ marginTop: 10, textAlign: "center",padding:'0px 10px'}}
                        >
                          {v.snippet.title
                            .replace("&amp;", "&")
                            .replace("&#39;", "'")}
                        </h5>
                        {/* <p>hello</p> */}
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <br />
            <div className="col-auto">
              <Link
                className="button button-primary"
                to="https://www.youtube.com/c/EarnWithRK/videos"
                target="_blank"
              >
                View All Videos
              </Link>
            </div>
          </div>
        </div>
      </div>

      {services.length > 0 && (
        <div className="strip">
          <div className="container pt-2 pb-6 pb-md-10">
            <div
              style={{ width: "100%", textAlign: "center", marginBottom: 30 }}
            >
              <h2 className="feature-title">Our Services</h2>
            </div>

            <div className="row justify-content-start">
              {services.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-4 mb-1">
                  <div className="service service-summary">
                    <div className="service-content">
                      <h2 className="service-title">
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title}
                        </Link>
                      </h2>
                      <p>{node.excerpt}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <Link className="button button-primary" to="/services/">
                  View All Services
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {features.length > 0 && (
        <div className="strip strip-grey">
          <div className="container pt-6 pb-6 pt-md-10 pb-md-10">
            <div className="row justify-content-center">
              {features.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                  <div className="feature">
                    {node.image && (
                      <div className="feature-image">
                        <img src={node.image} />
                      </div>
                    )}
                    <h2 className="feature-title">{node.title}</h2>
                    <div className="feature-content">{node.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/.*/" } }
      sort: { fields: [frontmatter___weight], order: ASC }
      limit: 6
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
    features: allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
